<div class="mat-elevation-z8 loader-container" [class.no-scroll]="foldersLoading">
  <div appPermission class="parts-upload" [permissions]="[permission.PARTS_WRITE]">
    <div class="file-upload-container">
      <button mat-flat-button class="sync-button" [disabled]="storageSyncing" (click)="syncStorage()">
        <mat-icon [class.sync-icon_active]="storageSyncing">sync</mat-icon>
        <span>Sync google storage</span>
      </button>
      <app-file-upload accept="text/csv" label="Upload parts" [formControl]="partsFileControl"></app-file-upload>
      <button
        mat-flat-button
        color="primary"
        [disabled]="partsFileControl.invalid || partsUploading"
        (click)="uploadParts()"
      >
        <div class="upload-button">
          @if (partsUploading) {
            <mat-spinner diameter="16"></mat-spinner>
          } @else {
            <span>Upload</span>
          }
        </div>
      </button>
    </div>

    <div class="file-upload-container">
      <button mat-flat-button class="sync-button" [disabled]="storageMaterialsSyncing" (click)="syncStorageMaterials()">
        <mat-icon [class.sync-icon_active]="storageMaterialsSyncing">sync</mat-icon>
        <span>Sync google storage materials</span>
      </button>
      <app-file-upload
        accept="application/json"
        label="Upload materials"
        [formControl]="materialsFileControl"
      ></app-file-upload>
      <button
        mat-flat-button
        color="primary"
        [disabled]="materialsFileControl.invalid || materialsUploading"
        (click)="uploadMaterials()"
      >
        <div class="upload-button">
          @if (materialsUploading) {
            <mat-spinner diameter="16"></mat-spinner>
          } @else {
            <span>Upload</span>
          }
        </div>
      </button>
    </div>
  </div>

  @if (wrongRecords.length) {
    <h2 class="wrong-records-title">Wrong records:</h2>
    <div class="wrong-records">
      @for (wrongRecord of wrongRecords; track $index) {
        <div class="wrong-record">Line #{{ wrongRecord.incorrectStringIndex }} - {{ wrongRecord.errorMessage }}</div>
      }
    </div>
  }

  @if (materialsWrongRecords.length) {
    <h2 class="wrong-records-title">Wrong materials records:</h2>
    <div class="wrong-records">
      @for (wrongRecord of materialsWrongRecords; track $index) {
        <div class="wrong-record">Line #{{ wrongRecord.incorrectRecordName }} - {{ wrongRecord.errorMessage }}</div>
      }
    </div>
  }

  @if (wrongArticles.length) {
    <h2 class="wrong-records-title">Wrong articles:</h2>
    <div class="wrong-records">
      @for (wrongArticle of wrongArticles; track $index) {
        <div class="wrong-record"><a [href]="wrongArticle.url">Doc</a> - {{ wrongArticle.errorMessage }}</div>
      }
    </div>
  }

  <div class="table-container">
    @if (foldersLoading) {
      <div class="spinner-container">
        <mat-spinner diameter="32"></mat-spinner>
      </div>
    }

    <table mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef style="width: 100px">
          <div class="th-container">
            <span class="th-container__label">Actions</span>
          </div>
        </th>

        <td mat-cell *matCellDef="let partsGroup">
          <div class="actions">
            <a class="g-unstyled-anchor" [routerLink]="'/parts/' + partsGroup">
              <mat-icon>chevron_right</mat-icon>
            </a>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>
          <div class="th-container">
            <span class="th-container__label">Name</span>
          </div>
        </th>

        <td mat-cell *matCellDef="let partsGroup">{{ partsGroup }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>

      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>

  <mat-paginator showFirstLastButtons [pageSize]="20"></mat-paginator>
</div>
