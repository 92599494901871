<mat-card>
  <mat-card-header>
    <mat-card-title>VOKA Admin - Reference</mat-card-title>
  </mat-card-header>

  <mat-divider></mat-divider>

  <mat-card-content>
    <div class="description">
      This software product is designed to configure and administer the data of the current version of the VOKA
      application.
    </div>
    <div>
      All data are presented in the form of tables:
      <span class="tables-titles">Categories</span>, <span class="tables-titles">Pathologies</span>,
      <span class="tables-titles">Types</span> and <span class="tables-titles">Items</span>.
    </div>
    <div class="controls-title">All controls:</div>
    <div class="tables-controls-info">
      <div class="info">
        <mat-icon>add</mat-icon>
        <div>- Button for adding an item to the table</div>
      </div>
      <div class="info">
        <mat-icon>edit</mat-icon>
        <div>- Table element edit button</div>
      </div>
      <div class="info">
        <mat-icon>chevron_right</mat-icon>
        <div>- Button to navigate to the entity of the selected item</div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
