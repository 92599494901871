import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { environment } from '../../../environments/environment';
import { BundleDTOIn, BundlePartsDTOOut } from '../models/interfaces/bundle.interface';
import { Pagination } from '../models/interfaces/pagination.interface';
import {
  MaterialsWrongRecord,
  PartDTOIn,
  PartDTOOut,
  PartsCsvWrongRecords,
  UpdatedParts,
} from '../models/interfaces/part.interface';

@Injectable({ providedIn: 'root' })
export class PartsService {
  constructor(private httpClient: HttpClient) {}

  public getFolders$(): Observable<string[]> {
    return this.httpClient.get<string[]>(`${environment.apiUrl}/parts/packages`);
  }

  public getPart$(
    uuid: string,
    queryObj,
    pagination: { pageNo: number; pageSize: number },
  ): Observable<Pagination<PartDTOIn[]>> {
    return this.httpClient.post<Pagination<PartDTOIn[]>>(`${environment.apiUrl}/parts/packages/${uuid}`, queryObj, {
      params: pagination as unknown as { [key: string]: string },
    });
  }

  public updatePart$(parts: PartDTOOut[]): Observable<UpdatedParts> {
    return this.httpClient.put<UpdatedParts>(`${environment.apiUrl}/parts`, parts);
  }

  public getBundleParts$(bundleUuid: string): Observable<BundleDTOIn[]> {
    return this.httpClient.get<BundleDTOIn[]>(`${environment.apiUrl}/parts/${bundleUuid}`);
  }

  public updateBundleParts$(partsData: BundlePartsDTOOut[], bundleUuid: string): Observable<string[]> {
    return this.httpClient.put<string[]>(`${environment.apiUrl}/parts/domain?bundleUuid=${bundleUuid}`, partsData);
  }

  public addParts$(parts: File): Observable<PartsCsvWrongRecords> {
    const partsData: FormData = new FormData();
    partsData.append('parts', parts);

    return this.httpClient.post<PartsCsvWrongRecords>(`${environment.apiUrl}/parts/batch`, partsData);
  }

  public addMaterials$(materials: File): Observable<MaterialsWrongRecord[]> {
    const materialsData: FormData = new FormData();
    materialsData.append('materials', materials);

    return this.httpClient
      .put<Record<string, MaterialsWrongRecord[]>>(`${environment.apiUrl}/materials`, materialsData)
      .pipe(map((res) => res.wrongRecords));
  }

  public syncStorage$(): Observable<void> {
    return this.httpClient.post<void>(`${environment.apiUrl}/parts/synchronize`, null);
  }

  public syncStorageMaterials$(): Observable<void> {
    return this.httpClient.post<void>(`${environment.apiUrl}/materials/synchronize`, null);
  }
}
