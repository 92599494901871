import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { CanComponentDeactivate } from '../../../../core/models/interfaces/can-deactivate.interface';
import { QuestionService } from '../../../../core/services/api/question.service';
import { SnackBarService } from '../../../../core/services/snack-bar.service';
import { ConfirmComponent } from '../../../popups/confirm/confirm.component';
import { QuestionFormComponent } from '../question-form/question-form.component';

@Component({
  selector: 'app-question-edit',
  templateUrl: './question-edit.component.html',
  styleUrls: ['./question-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [QuestionFormComponent],
})
export class QuestionEditComponent implements OnInit, CanComponentDeactivate {
  public questionFormsData: any;
  public questionUuid: string;

  private formEdited: boolean;

  constructor(
    private cdRef: ChangeDetectorRef,
    private destroyRef: DestroyRef,
    private matDialog: MatDialog,
    private questionService: QuestionService,
    private route: ActivatedRoute,
    private snackBarService: SnackBarService,
  ) {}

  public ngOnInit(): void {
    this.initQuestionFormsData();
  }

  public canDeactivate(): boolean | Observable<boolean> {
    if (this.formEdited) {
      const dialogRef = this.matDialog.open(ConfirmComponent, {
        data: {
          title: 'Are you sure?',
          description: 'Changes you made will not be saved',
        },
      });

      return dialogRef.afterClosed().pipe(map(Boolean));
    }

    return true;
  }

  public editQuestion(question: any): void {
    this.questionService
      .updateQuestion$(this.questionUuid, question)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((editedQuestion) => {
        this.setFormEdited(false);
        this.setQuestionFormsData(editedQuestion);
        this.snackBarService.openSnackBar({
          content: {
            message: 'Question edited successfully',
          },
          status: 'success',
        });
        this.questionService.setSaveButtonState(true);
      });
  }

  public setFormEdited(formEdited: boolean): void {
    this.formEdited = formEdited;
  }

  private initQuestionFormsData(): void {
    this.route.params
      .pipe(
        take(1),
        tap(({ uuid }) => {
          this.questionUuid = uuid;
        }),
        switchMap(({ uuid }) => this.questionService.getQuestion$(uuid)),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((question) => {
        this.setQuestionFormsData(question);
      });
  }

  private setQuestionFormsData(question): void {
    this.questionFormsData = {
      de: {
        question: question.questionDe,
        answer: question.answerDe,
      },
      en: {
        question: question.questionEn,
        answer: question.answerEn,
      },
      ru: {
        question: question.questionRu,
        answer: question.answerRu,
      },
      sequence: question.sequence,
    };

    this.cdRef.markForCheck();
  }
}
