import { NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  DestroyRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChildren,
  ViewContainerRef,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { Router } from '@angular/router';
import { QuillEditorComponent } from 'ngx-quill';
import Quill from 'quill';
import { take } from 'rxjs/operators';
import { InputComponent } from '../../../../core/components/controls/input/input.component';
import { TextareaComponent } from '../../../../core/components/controls/textarea/textarea.component';
import { QuestionService } from '../../../../core/services/api/question.service';
import { EmbedResource } from '../../configs/embed-resource.enum';
import { FAQ_CONTROLS_ERRORS } from '../../configs/error-messages';
import { FAQ_TEXT_BLOCKS_CONTROLS } from '../../configs/faq-text-blocks-controls';
import { EmbedUrlComponent } from '../embed-url/embed-url.component';

const Delta = Quill.import('delta');

@Component({
  selector: 'app-question-form',
  templateUrl: './question-form.component.html',
  styleUrls: ['./question-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    InputComponent,
    MatButtonModule,
    MatIconModule,
    NgTemplateOutlet,
    QuillEditorComponent,
    ReactiveFormsModule,
    TextareaComponent,
  ],
})
export class QuestionFormComponent implements OnChanges, OnInit {
  @Input() public initialData: any = null;

  @Output() public question = new EventEmitter<any>();
  @Output() public formChanged = new EventEmitter<boolean>();

  @HostListener('click', ['$event']) public componentClick(event: MouseEvent): void {
    if (!event.composedPath().includes(this.embedRef?.location?.nativeElement)) {
      this.editorsWrappers.forEach((item) => {
        item.clear();
      });
    }
  }

  @ViewChildren('embedUploadWrapper', { read: ViewContainerRef })
  public editorsWrappers: QueryList<ViewContainerRef>;

  public readonly textBlocksControls = FAQ_TEXT_BLOCKS_CONTROLS;
  public readonly errors = FAQ_CONTROLS_ERRORS;
  public readonly embedResource = EmbedResource;

  public questionForm: UntypedFormGroup;
  public saveDisabled = true;
  public formValueInitialized = false;

  private editorInstances: Quill[] = [];

  private embedRef: ComponentRef<EmbedUrlComponent>;

  constructor(
    private cdRef: ChangeDetectorRef,
    private destroyRef: DestroyRef,
    private fb: UntypedFormBuilder,
    private router: Router,
    private questionService: QuestionService,
  ) {}

  public ngOnChanges(changes: SimpleChanges): void {
    this.initQuestionForms();
    if (changes['initialData']?.currentValue && changes['initialData']?.firstChange) {
      this.fillQuestionForms(changes['initialData'].currentValue);
    }
  }

  public ngOnInit(): void {
    this.initQuestionForms();
    this.watchButtonDisabled();
  }

  public setEditorInstance(editor: Quill) {
    editor.clipboard.addMatcher(Node.TEXT_NODE, function (node, delta) {
      return delta.compose(new Delta().retain(delta.length(), { background: 'transparent' }));
    });

    this.editorInstances.push(editor);
  }

  public uploadEmbed(event: Event, id: number, type: string): void {
    event.stopPropagation();

    const vcr = this.editorsWrappers.get(id);

    vcr.clear();

    this.embedRef = vcr.createComponent(EmbedUrlComponent);

    this.embedRef.instance.placeholderText =
      type === EmbedResource.Image ? 'Enter image embed url' : 'Enter video embed url';

    const range = this.editorInstances[id].getSelection(true);

    this.embedRef.instance.embedUrl.pipe(take(1)).subscribe((url) => {
      this.editorInstances[id].insertEmbed(range.index, type, url, 'user');
      this.editorsWrappers.get(id).clear();
    });
  }

  private watchButtonDisabled(): void {
    this.questionService.saveButtonDisabled$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((disabled) => {
      this.saveDisabled = disabled;
      this.cdRef.markForCheck();
    });
  }

  public save(): void {
    this.questionForm.markAllAsTouched();
    this.cdRef.markForCheck();

    if (this.questionForm.valid) {
      const formData = this.questionForm.getRawValue();

      const question = {
        questionDe: formData.de.question,
        questionEn: formData.en.question,
        questionRu: formData.ru.question,
        answerDe: formData.de.answer,
        answerEn: formData.en.answer,
        answerRu: formData.ru.answer,
        sequence: +formData.sequence || 0,
      };

      this.question.emit(question);
    }
  }

  public cancel(): void {
    this.router.navigate(['faq']);
  }

  private initQuestionForms(): void {
    if (this.questionForm) return;

    this.questionForm = this.fb.group({
      en: this.fb.group({
        question: [null, [Validators.required]],
        answer: [null, [Validators.required]],
      }),
      ru: this.fb.group({
        question: [null, [Validators.required]],
        answer: [null, [Validators.required]],
      }),
      de: this.fb.group({
        question: [null, [Validators.required]],
        answer: [null, [Validators.required]],
      }),
      sequence: [0],
    });

    this.questionForm.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      if (this.questionForm.dirty && this.saveDisabled) {
        this.questionService.setSaveButtonState(false);
        this.formChanged.emit(true);
      }
    });
  }

  private fillQuestionForms(formsData: any): void {
    this.questionForm.patchValue(formsData, { emitEvent: false });
  }
}
