<mat-form-field class="container">
  @if (labelText) {
    <mat-label>{{ labelText }}</mat-label>
  }

  <textarea matInput [formControl]="control" [placeholder]="placeholderText" (blur)="blur($event)"></textarea>

  @if (control.invalid && control.touched) {
    <mat-error>{{ errorText }}</mat-error>
  }
</mat-form-field>
