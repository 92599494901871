import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { enableProdMode, importProvidersFrom } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DateFnsAdapter, MAT_DATE_FNS_FORMATS } from '@angular/material-date-fns-adapter';
import { MAT_AUTOCOMPLETE_SCROLL_STRATEGY_FACTORY_PROVIDER } from '@angular/material/autocomplete';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, withHashLocation } from '@angular/router';
import { enGB } from 'date-fns/locale';
import { QuillModule } from 'ngx-quill';
import { AppComponent } from './app/app.component';
import { routes } from './app/app.routes';
import { QUILL_CONFIG } from './app/core/constants/quill-config.const';
import { TokenInterceptor } from './app/core/interceptors/token.interceptor';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      BrowserModule,
      FormsModule,
      MatIconModule,
      MatNativeDateModule,
      MatSnackBarModule,
      ReactiveFormsModule,
      QuillModule.forRoot(QUILL_CONFIG),
    ),
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { autoFocus: false, minWidth: 400 } },
    { provide: MAT_DATE_LOCALE, useValue: enGB },
    { provide: MAT_DATE_FORMATS, useValue: MAT_DATE_FNS_FORMATS },
    { provide: DateAdapter, useClass: DateFnsAdapter },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    MAT_AUTOCOMPLETE_SCROLL_STRATEGY_FACTORY_PROVIDER,
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi()),
    provideRouter(routes, withHashLocation()),
  ],
}).catch((err) => console.error(err));
