import { Routes } from '@angular/router';
import { DashboardComponent } from '../dashboard/dashboard.component';
import { faqRoutes } from '../faq/faq.routes';
import { partsRoutes } from '../parts/parts.routes';

export const mainRoutes: Routes = [
  {
    path: 'dashboard',
    component: DashboardComponent,
  },
  {
    path: 'categories/:id/pathologies/:pathologyId/types/:typeId/model',
    loadChildren: () => import('../model/model.routes').then((_) => _.modelRoutes),
  },
  {
    path: 'categories/:id/pathologies/:pathologyId/types/:typeId',
    loadComponent: () => import('../type-table/type-table.component').then((_) => _.TypeTableComponent),
  },
  {
    path: 'categories/:id/pathologies/:pathologyId/types',
    loadComponent: () => import('../pathology-table/pathology-table.component').then((_) => _.PathologyTableComponent),
  },
  {
    path: 'categories/:id/pathologies',
    loadComponent: () => import('../category-table/category-table.component').then((_) => _.CategoryTableComponent),
  },
  {
    path: 'categories',
    loadComponent: () =>
      import('../categories-table/categories-table.component').then((_) => _.CategoriesTableComponent),
  },
  {
    path: 'parts',
    children: partsRoutes,
  },
  {
    path: 'users',
    loadComponent: () => import('../users/users.component').then((_) => _.UsersComponent),
  },
  {
    path: 'roles',
    loadComponent: () => import('../roles/roles.component').then((_) => _.RolesComponent),
  },

  {
    path: 'admins',
    loadComponent: () => import('../admins/admins.component').then((_) => _.AdminsComponent),
  },

  {
    path: 'partners',
    loadComponent: () => import('../partners/partners.component').then((_) => _.PartnersComponent),
  },
  ...faqRoutes,
  {
    path: 'subscriptions',
    loadComponent: () => import('../subscriptions/subscriptions.component').then((_) => _.SubscriptionsComponent),
  },
  {
    path: 'licenses',
    loadComponent: () => import('../partner-details/partner-details.component').then((_) => _.PartnerDetailsComponent),
  },
  {
    path: 'accounts',
    loadComponent: () => import('../partner-details/partner-details.component').then((_) => _.PartnerDetailsComponent),
  },
  {
    path: 'article/:id',
    loadComponent: () => import('../create-article/create-article.component').then((_) => _.CreateArticleComponent),
  },
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: 'dashboard',
  },
];
