import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { BreadcrumbsService } from '../../core/services/breadcrumbs.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'dashboard.component.html',
  styleUrls: ['dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatCardModule, MatDividerModule, MatIconModule],
})
export class DashboardComponent implements OnInit {
  constructor(private breadcrumbsService: BreadcrumbsService) {}

  public ngOnInit(): void {
    this.breadcrumbsService.clearBreadcrumbs();
  }
}
