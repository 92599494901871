import { Routes } from '@angular/router';
import { PartsGroupsComponent } from './parts-groups/parts-groups.component';
import { PartsComponent } from './parts/parts.component';

export const partsRoutes: Routes = [
  {
    path: '',
    component: PartsGroupsComponent,
  },
  {
    path: ':folder',
    component: PartsComponent,
  },
];
