import { Component, HostListener, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { RouterOutlet } from '@angular/router';
import { AuthService } from './core/services/auth.service';
import { ItemService } from './pages/services/item.service';

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>',
  imports: [RouterOutlet],
})
export class AppComponent implements OnInit {
  @HostListener('window:beforeunload', ['$event'])
  public canDeactivate(event): void {
    if (this.itemService.getUploadingIds().length) {
      event.preventDefault();
      event.returnValue = '';
    }
  }

  constructor(
    private authService: AuthService,
    private itemService: ItemService,
    private matIconReg: MatIconRegistry,
  ) {}

  public ngOnInit(): void {
    this.initUser();
    this.matIconReg.setDefaultFontSetClass('material-symbols-outlined');
  }

  private initUser(): void {
    this.authService.initUser();
  }
}
