import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { LocalStorageKeys } from '../models/enums/local-storage-keys';
import { AuthInDTO, AuthOutDTO } from '../models/interfaces/auth.interfaces';
import { PermissionService } from './permission.service';
import { SnackBarService } from './snack-bar.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public accessToken = new BehaviorSubject<string>(null);

  private httpBackend: HttpClient;

  constructor(
    private http: HttpClient,
    private httpBack: HttpBackend,
    private router: Router,
    private permissionService: PermissionService,
    private snackBarService: SnackBarService,
  ) {
    this.httpBackend = new HttpClient(this.httpBack);
  }

  public singIn$(body: AuthOutDTO): Observable<AuthInDTO> {
    return this.httpBackend.post<AuthInDTO>(`${environment.apiUrl}/auth/login`, body);
  }

  public signOut$(): Observable<void> {
    return this.http.post<void>(`${environment.apiUrl}/auth/logout`, {});
  }

  public saveAuthData(body: AuthInDTO) {
    localStorage.setItem(LocalStorageKeys.AccessToken, JSON.stringify(body.accessToken));
    localStorage.setItem(LocalStorageKeys.RefreshToken, JSON.stringify(body.refreshToken));

    const permissions = body.permissions.map((el) => el.permission);
    localStorage.setItem(LocalStorageKeys.Permissions, JSON.stringify(permissions));

    this.setAccessToken(body.accessToken);
    this.permissionService.setPermissions(permissions);
  }

  public removeAuthData() {
    localStorage.removeItem(LocalStorageKeys.AccessToken);
    localStorage.removeItem(LocalStorageKeys.RefreshToken);
    localStorage.removeItem(LocalStorageKeys.Permissions);
    this.setAccessToken(null);
    this.permissionService.setPermissions(null);
  }

  public initUser(): void {
    const token = JSON.parse(localStorage.getItem(LocalStorageKeys.AccessToken));
    const permissions = JSON.parse(localStorage.getItem(LocalStorageKeys.Permissions));

    if (token !== null) {
      this.setAccessToken(token);
    }

    if (permissions !== null) {
      this.permissionService.setPermissions(permissions);
    }
  }

  public getAccessToken$(): Observable<string> {
    return this.accessToken.asObservable();
  }

  private setAccessToken(token: string): void {
    this.accessToken.next(token);
  }

  public refreshToken$(): Observable<{ accessToken: string }> {
    const refreshToken = JSON.parse(localStorage.getItem(LocalStorageKeys.RefreshToken));

    return this.httpBackend
      .post<{ accessToken: string }>(`${environment.apiUrl}/auth/refresh`, null, {
        headers: {
          'Authorization-Refresh': refreshToken,
        },
      })
      .pipe(
        tap(({ accessToken }) => {
          localStorage.setItem(LocalStorageKeys.AccessToken, JSON.stringify(accessToken));
        }),
        catchError((error) => {
          this.removeAuthData();
          this.snackBarService.openSnackBar({
            content: {
              message: error.message,
            },
            status: 'error',
          });
          this.router.navigate(['auth']);
          return throwError(() => error);
        }),
      );
  }
}
