import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

export const adminActivationGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  _state: RouterStateSnapshot,
): Observable<boolean | UrlTree> => {
  const router = inject(Router);

  return (route.queryParams['token'] && route.queryParams['userUuid']) || router.createUrlTree(['/']);
};
