export const FAQ_CONTROLS_ERRORS = {
  question: {
    required: 'Please enter your question',
  },
  answer: {
    required: 'Please enter your answer',
  },
};

export const EMBED_URL_ERRORS = {
  embedUrlControl: {
    pattern: "Seems like it's an invalid URL",
  },
};
