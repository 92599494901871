import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { RouterModule } from '@angular/router';
import { finalize } from 'rxjs';
import { FileUploadComponent } from '../../../core/components/controls/file-upload/file-upload.component';
import { CheckPermissionDirective } from '../../../core/directives/check-permission/check-permission.directive';
import { Permission } from '../../../core/models/enums/permissions.enum';
import {
  ArticleWrongRecord,
  MaterialsWrongRecord,
  PartBatchWrongRecord,
} from '../../../core/models/interfaces/part.interface';
import { BreadcrumbsService } from '../../../core/services/breadcrumbs.service';
import { PartsService } from '../../../core/services/parts.service';
import { SnackBarService } from '../../../core/services/snack-bar.service';

@Component({
  selector: 'app-parts-groups',
  templateUrl: './parts-groups.component.html',
  styleUrls: ['../../styles/table.scss', './parts-groups.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CheckPermissionDirective,
    FileUploadComponent,
    MatButtonModule,
    MatIconModule,
    MatPaginatorModule,
    MatProgressSpinner,
    MatTableModule,
    ReactiveFormsModule,
    RouterModule,
  ],
})
export class PartsGroupsComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator) public paginator: MatPaginator;

  public foldersLoading = false;
  public dataSource = new MatTableDataSource<string>([]);
  public wrongRecords: PartBatchWrongRecord[] = [];
  public wrongArticles: ArticleWrongRecord[] = [];
  public materialsWrongRecords: MaterialsWrongRecord[] = [];
  public displayedColumns = ['actions', 'name'];
  public partsUploading = false;
  public materialsUploading = false;
  public storageSyncing = false;
  public storageMaterialsSyncing = false;

  public permission = Permission;

  public partsFileControl = new FormControl<File>(null, [Validators.required]);
  public materialsFileControl = new FormControl<File>(null, [Validators.required]);

  constructor(
    private breadcrumbsService: BreadcrumbsService,
    private cdRef: ChangeDetectorRef,
    private destroyRef: DestroyRef,
    private snackBarService: SnackBarService,
    private partsService: PartsService,
  ) {}

  public ngOnInit(): void {
    this.breadcrumbsService.clearBreadcrumbs();

    this.initFoldersData();
  }

  public ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  public uploadParts(): void {
    this.partsFileControl.markAsTouched();

    if (this.partsFileControl.valid) {
      this.partsUploading = true;

      const file = this.partsFileControl.value;

      this.partsService
        .addParts$(file)
        .pipe(
          finalize(() => {
            this.partsUploading = false;
            this.cdRef.markForCheck();
          }),
          takeUntilDestroyed(this.destroyRef),
        )
        .subscribe({
          next: (records) => {
            this.wrongRecords = records.wrongRecords;
            this.wrongArticles = records.wrongArticles;
            this.snackBarService.openSuccessSnackBar({
              message: 'Parts were successfully added',
            });
          },
          error: (err) => {
            this.snackBarService.openErrorSnackBar(err.error);
          },
        });
    }
  }

  public uploadMaterials(): void {
    this.materialsFileControl.markAsTouched();

    if (this.materialsFileControl.valid) {
      this.materialsUploading = true;

      const file = this.materialsFileControl.value;

      this.partsService
        .addMaterials$(file)
        .pipe(
          finalize(() => {
            this.materialsUploading = false;
            this.cdRef.markForCheck();
          }),
          takeUntilDestroyed(this.destroyRef),
        )
        .subscribe({
          next: (wrongRecords) => {
            this.materialsWrongRecords = wrongRecords;
            this.snackBarService.openSuccessSnackBar({
              message: 'Materials were successfully added',
            });
          },
          error: (err) => {
            this.snackBarService.openErrorSnackBar(err.error);
          },
        });
    }
  }

  public syncStorage(): void {
    this.storageSyncing = true;

    this.partsService
      .syncStorage$()
      .pipe(
        finalize(() => {
          this.storageSyncing = false;
          this.cdRef.markForCheck();
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe({
        next: () => {
          this.snackBarService.openSuccessSnackBar({
            message: 'Storage synchronized',
          });
        },
        error: (err) => {
          this.snackBarService.openErrorSnackBar(err.error);
        },
      });
  }

  public syncStorageMaterials(): void {
    this.storageMaterialsSyncing = true;

    this.partsService
      .syncStorageMaterials$()
      .pipe(
        finalize(() => {
          this.storageMaterialsSyncing = false;
          this.cdRef.markForCheck();
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe({
        next: () => {
          this.snackBarService.openSuccessSnackBar({
            message: 'Storage materials synchronized',
          });
        },
        error: (err) => {
          this.snackBarService.openErrorSnackBar(err.error);
        },
      });
  }

  private initFoldersData(): void {
    this.foldersLoading = true;

    this.partsService
      .getFolders$()
      .pipe(
        finalize(() => {
          this.foldersLoading = false;
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe({
        next: (folders) => {
          this.dataSource.data = folders;
        },
        error: (err) => {
          this.snackBarService.openErrorSnackBar(err.error);
        },
      });
  }
}
