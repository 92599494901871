import { Routes } from '@angular/router';
import { questionLeaveGuard } from '../../core/guards/question-leave.guard';
import { QuestionAddComponent } from './components/question-add/question-add.component';
import { QuestionEditComponent } from './components/question-edit/question-edit.component';

export const faqRoutes: Routes = [
  {
    path: 'faq/question/:uuid',
    component: QuestionEditComponent,
    canDeactivate: [questionLeaveGuard],
  },
  {
    path: 'faq/question',
    component: QuestionAddComponent,
    canDeactivate: [questionLeaveGuard],
  },
  {
    path: 'faq',
    loadComponent: () => import('../faq/faq.component').then((_) => _.FaqComponent),
  },
];
