import { QuillConfig } from 'ngx-quill';

export const QUILL_CONFIG: QuillConfig = {
  customOptions: [
    {
      import: 'attributors/style/size',
      whitelist: ['24px', '22px', '20px', '18px', '16px', '14px'],
    },
    {
      import: 'formats/font',
      whitelist: ['manrope-bold'],
    },
  ],
};
