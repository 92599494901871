<div class="mat-elevation-z8 loader-container" [class.no-scroll]="dataLoading">
  @if (articlesErrors.length) {
    <h2 class="wrong-records-title">Wrong articles:</h2>
    <div class="wrong-records">
      @for (wrongArticle of articlesErrors; track $index) {
        <div class="wrong-record"><a [href]="wrongArticle.url">Doc</a> - {{ wrongArticle.errorMessage }}</div>
      }
    </div>
  }

  <div class="table-container">
    @if (dataLoading) {
      <div class="spinner-container">
        <mat-spinner diameter="32"></mat-spinner>
      </div>
    }

    <table mat-table [dataSource]="dataSource" [formGroup]="partsFilterForm">
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>
          <div class="th-container">
            <span class="th-container__label">Actions</span>

            <button
              appPermission
              mat-mini-fab
              color="primary"
              [permissions]="[permission.PARTS_UPDATE]"
              (click)="saveParts()"
            >
              <mat-icon>save</mat-icon>
            </button>
          </div>
        </th>

        <td mat-cell *matCellDef="let part">
          <div class="actions">
            <button disabled class="g-unstyled-button">
              <mat-icon>chevron_right</mat-icon>
            </button>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>
          <div class="th-container">
            <span class="th-container__label">Name</span>

            <mat-form-field class="filter-control">
              <input matInput placeholder="Type to search..." formControlName="name" />
            </mat-form-field>
          </div>
        </th>

        <td mat-cell *matCellDef="let part">{{ part?.get('name')?.value }}</td>
      </ng-container>

      <ng-container matColumnDef="labelEn">
        <th mat-header-cell *matHeaderCellDef>
          <div class="th-container">
            <span class="th-container__label"
              >Label EN
              <mat-checkbox formControlName="emptylabelEn"></mat-checkbox>
            </span>
            <mat-form-field class="filter-control">
              <input matInput placeholder="Type to search..." formControlName="labelEn" />
            </mat-form-field>
          </div>
        </th>

        <td mat-cell *matCellDef="let part">
          <mat-form-field subscriptSizing="dynamic" appearance="outline">
            <input matInput type="text" [formControl]="part?.get('labelEn')" />
          </mat-form-field>
        </td>
      </ng-container>

      <ng-container matColumnDef="labelRu">
        <th mat-header-cell *matHeaderCellDef>
          <div class="th-container">
            <span class="th-container__label"
              >Label RU
              <mat-checkbox formControlName="emptylabelRu"></mat-checkbox>
            </span>
            <mat-form-field class="filter-control">
              <input matInput placeholder="Type to search..." formControlName="labelRu" />
            </mat-form-field>
          </div>
        </th>

        <td mat-cell *matCellDef="let part">
          <mat-form-field subscriptSizing="dynamic" appearance="outline">
            <input matInput type="text" [formControl]="part?.get('labelRu')" />
          </mat-form-field>
        </td>
      </ng-container>

      <ng-container matColumnDef="labelDe">
        <th mat-header-cell *matHeaderCellDef>
          <div class="th-container">
            <span class="th-container__label"
              >Label DE
              <mat-checkbox formControlName="emptylabelDe"></mat-checkbox>
            </span>
            <mat-form-field class="filter-control">
              <input matInput placeholder="Type to search..." formControlName="labelDe" />
            </mat-form-field>
          </div>
        </th>

        <td mat-cell *matCellDef="let part">
          <mat-form-field subscriptSizing="dynamic" appearance="outline">
            <input matInput type="text" [formControl]="part?.get('labelDe')" />
          </mat-form-field>
        </td>
      </ng-container>

      <ng-container matColumnDef="labelLa">
        <th mat-header-cell *matHeaderCellDef>
          <div class="th-container">
            <span class="th-container__label"
              >Label LA
              <mat-checkbox formControlName="emptylabelLa"></mat-checkbox>
            </span>
            <mat-form-field class="filter-control">
              <input matInput placeholder="Type to search..." formControlName="labelLa" />
            </mat-form-field>
          </div>
        </th>

        <td mat-cell *matCellDef="let part">
          <mat-form-field subscriptSizing="dynamic" appearance="outline">
            <input matInput type="text" [formControl]="part?.get('labelLa')" />
          </mat-form-field>
        </td>
      </ng-container>

      <ng-container matColumnDef="articleEn">
        <th mat-header-cell *matHeaderCellDef>
          <div class="th-container">
            <span class="th-container__label"
              >Article EN
              <mat-checkbox formControlName="articleEn"></mat-checkbox>
            </span>
          </div>
        </th>

        <td mat-cell *matCellDef="let part">
          <div class="article-cell">
            <mat-form-field subscriptSizing="dynamic" appearance="outline">
              <input matInput type="text" [formControl]="part?.get('articleEn')" />
            </mat-form-field>
            <input type="checkbox" [formControl]="part?.get('reviewArticleEn')" />
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="articleRu">
        <th mat-header-cell *matHeaderCellDef>
          <div class="th-container">
            <span class="th-container__label"
              >Article RU
              <mat-checkbox formControlName="articleRu"></mat-checkbox>
            </span>
          </div>
        </th>

        <td mat-cell *matCellDef="let part">
          <div class="article-cell">
            <mat-form-field subscriptSizing="dynamic" appearance="outline">
              <input matInput type="text" [formControl]="part?.get('articleRu')" />
            </mat-form-field>
            <input type="checkbox" [formControl]="part?.get('reviewArticleRu')" />
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="articleDe">
        <th mat-header-cell *matHeaderCellDef>
          <div class="th-container">
            <span class="th-container__label"
              >Article DE
              <mat-checkbox formControlName="articleDe"></mat-checkbox>
            </span>
          </div>
        </th>

        <td mat-cell *matCellDef="let part">
          <div class="article-cell">
            <mat-form-field subscriptSizing="dynamic" appearance="outline">
              <input matInput type="text" [formControl]="part?.get('articleDe')" />
            </mat-form-field>
            <input type="checkbox" [formControl]="part?.get('reviewArticleDe')" />
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>

      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>

  <mat-paginator
    showFirstLastButtons
    [length]="partsTotalCount"
    [pageSize]="defaultPageSize"
    [pageSizeOptions]="[10, 50, 100]"
  ></mat-paginator>
</div>
