import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  SecurityContext,
  ViewChild,
} from '@angular/core';
import { ReactiveFormsModule, UntypedFormControl, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { DomSanitizer } from '@angular/platform-browser';
import { InputComponent } from '../../../../core/components/controls/input/input.component';
import { URL_REGEXP } from '../../../../core/constants/regexp';
import { EMBED_URL_ERRORS } from '../../configs/error-messages';

@Component({
  selector: 'app-embed-url',
  templateUrl: './embed-url.component.html',
  styleUrls: ['./embed-url.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [InputComponent, MatButtonModule, ReactiveFormsModule],
})
export class EmbedUrlComponent implements AfterViewInit {
  @Input() public placeholderText: string = '';

  @Output() public embedUrl = new EventEmitter<string>();

  @ViewChild('input', { read: InputComponent }) private input: InputComponent;

  public embedUrlControl = new UntypedFormControl('', Validators.pattern(URL_REGEXP));
  public errors = EMBED_URL_ERRORS;

  constructor(private domSanitizer: DomSanitizer) {}

  public ngAfterViewInit(): void {
    this.input.focus();
  }

  public saveEmbedUrl(): void {
    if (this.embedUrlControl.valid) {
      const safeUrl = this.domSanitizer.sanitize(SecurityContext.URL, this.embedUrlControl.value);

      if (safeUrl) {
        this.embedUrl.emit(safeUrl);
      }
    }
  }
}
