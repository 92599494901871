import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { QuestionDtoIn, QuestionDtoOut } from '../../../pages/faq/interfaces/question.interface';

@Injectable({ providedIn: 'root' })
export class QuestionService {
  public saveButtonDisabled$: Observable<boolean>;

  private saveButtonDisabled = new BehaviorSubject<boolean>(true);

  private readonly apiUrl = `${environment.apiUrl}/faqs`;

  constructor(private httpClient: HttpClient) {
    this.saveButtonDisabled$ = this.saveButtonDisabled.asObservable();
  }

  public getQuestion$(uuid: string): Observable<QuestionDtoIn> {
    return this.httpClient.get<QuestionDtoIn>(`${this.apiUrl}/${uuid}`);
  }

  public getQuestions$(): Observable<QuestionDtoIn[]> {
    return this.httpClient.get<QuestionDtoIn[]>(this.apiUrl);
  }

  public postQuestion$(question: QuestionDtoOut): Observable<QuestionDtoIn> {
    return this.httpClient.post<QuestionDtoIn>(this.apiUrl, question);
  }

  public updateQuestion$(uuid: string, question: QuestionDtoOut): Observable<QuestionDtoIn> {
    return this.httpClient.put<QuestionDtoIn>(`${this.apiUrl}/${uuid}`, question);
  }

  public deleteQuestion$(uuid: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.apiUrl}/${uuid}`);
  }

  public setSaveButtonState(disabled: boolean) {
    this.saveButtonDisabled.next(disabled);
  }
}
