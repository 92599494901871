import { Routes } from '@angular/router';
import { adminActivationGuard } from './core/guards/admin-activation.guard';
import { authGuard } from './core/guards/auth.guard';
import { pagesGuard } from './core/guards/pages.guard';
import { permissionGuard } from './core/guards/permission.guard';
import { mainRoutes } from './pages/main/main.routes';

export const routes: Routes = [
  {
    path: 'auth',
    loadComponent: () => import('./pages/auth/auth.component').then((_) => _.AuthComponent),
    canActivate: [authGuard],
  },
  {
    path: 'admin-activation',
    loadComponent: () =>
      import('./pages/admin-activation/admin-activation.component').then((_) => _.AdminActivationComponent),
    canActivate: [adminActivationGuard],
  },
  {
    path: '',
    loadComponent: () => import('./pages/main/main.component').then((_) => _.MainComponent),
    canActivate: [pagesGuard],
    children: mainRoutes,
    canActivateChild: [permissionGuard],
  },
  { path: '**', redirectTo: '' },
];
