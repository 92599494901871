import { NgStyle } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { ERROR_500MB } from '../../../../pages/popups/constants/popup-constants';
import { ControlBase } from '../control-base.directive';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatIconModule, NgStyle],
})
export class FileUploadComponent extends ControlBase implements OnInit {
  @ViewChild('fileInput') public fileInputRef: ElementRef<HTMLInputElement>;

  @Input() public accept: string;
  @Input() public label: string;

  public correctFileSize = true;

  public ERROR_500MB = ERROR_500MB;

  public override writeValue(value: any): void {
    this.control.setValue(value, {
      emitEvent: false,
    });

    if (!value && this.fileInputRef) {
      this.deleteFile(false);
    }
  }

  public fileChanged(event: Event): void {
    const file = (event.target as HTMLInputElement).files?.[0];
    this.correctFileSize = file?.size <= 524_288_000;
    this.control.setValue(file);
    this.ngControl.control.markAsTouched();
  }

  public deleteFile(dispatchNative = true): void {
    this.fileInputRef.nativeElement.value = null;
    this.correctFileSize = true;

    if (dispatchNative) {
      this.fileInputRef.nativeElement.dispatchEvent(new Event('change'));
    }
  }
}
