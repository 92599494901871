<form [formGroup]="questionForm" (ngSubmit)="save()">
  @for (control of textBlocksControls; let idx = $index; track idx) {
    <ng-container [formGroupName]="control.name">
      <ng-container>
        <span class="editor-title">{{ control.text }}</span>
        <app-textarea
          formControlName="question"
          placeholderText="Question"
          [errorMessages]="errors.question"
        ></app-textarea>

        <div class="label">Answer</div>
        <div class="editor-wrapper">
          <quill-editor
            classes="editor"
            formControlName="answer"
            [filterNull]="true"
            [customOptions]="[
              {
                import: 'attributors/style/size',
                whitelist: ['24px', '22px', '20px', '18px', '16px', '14px'],
              },
              {
                import: 'formats/font',
                whitelist: ['manrope-bold'],
              },
            ]"
            (onEditorCreated)="setEditorInstance($event)"
          >
            <div quill-editor-toolbar>
              <ng-container [ngTemplateOutlet]="toolbar" [ngTemplateOutletContext]="{ id: idx }"></ng-container>
            </div>
          </quill-editor>
          <div #embedUploadWrapper></div>
        </div>
        @if (
          questionForm.controls[control.name]['controls']['answer'].invalid &&
          questionForm.controls[control.name]['controls']['answer'].touched
        ) {
          <span class="error">
            {{ errors.answer.required }}
          </span>
        }
      </ng-container>
    </ng-container>
  }

  <app-input class="sequence" formControlName="sequence" labelText="Sequence" placeholderText="Sequence"></app-input>

  <div class="action-buttons">
    <button mat-flat-button class="button" color="primary" type="submit" [disabled]="saveDisabled">Save</button>
    <button mat-flat-button class="button" color="warn" type="button" (click)="cancel()">Cancel</button>
  </div>
</form>

<ng-template #toolbar let-id="id">
  <span class="ql-formats">
    <button class="ql-bold" title="Bold"></button>
    <button class="ql-italic" title="Italic"></button>
  </span>
  <span class="ql-formats">
    <select class="ql-header">
      <option selected></option>
      <option value="2"></option>
      <option value="3"></option>
    </select>
  </span>

  <span class="ql-formats">
    <button class="ql-align" value="justify" title="Justify"></button>
    <button class="ql-align" value title="Left"></button>
    <button class="ql-align" value="center" title="Center"></button>
    <button class="ql-align" value="right" title="Right"></button>
  </span>
  <span class="ql-formats">
    <select class="ql-font" title="Fonts">
      <option selected>Manrope Normal</option>
      <option value="manrope-bold">Manrope Bold</option>
    </select>
    <select class="ql-size" title="Font size">
      <option selected value="14px">14px</option>
      <option value="16px"></option>
      <option value="18px"></option>
      <option value="20px"></option>
      <option value="22px"></option>
      <option value="24px"></option>
    </select>
  </span>
  <span class="ql-formats">
    <button class="ql-list" value="ordered" title="Ordered"></button>
    <button class="ql-list" value="bullet" title="Bullet"></button>
  </span>
  <span class="ql-formats">
    <button class="ql-link" type="button" title="Make link"></button>
  </span>
  <span class="ql-formats">
    <button title="Image" type="button" (click)="uploadEmbed($event, id, embedResource.Image)">
      <mat-icon>image</mat-icon>
    </button>
    <button title="Video" type="button" (click)="uploadEmbed($event, id, embedResource.Video)">
      <mat-icon>movie</mat-icon>
    </button>
  </span>
</ng-template>
