export const FAQ_TEXT_BLOCKS_CONTROLS = [
  {
    name: 'en',
    text: 'English',
  },
  {
    name: 'de',
    text: 'Deutsch',
  },
  {
    name: 'ru',
    text: 'Русский',
  },
];
