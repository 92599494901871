import { ChangeDetectionStrategy, Component, DestroyRef } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CanComponentDeactivate } from '../../../../core/models/interfaces/can-deactivate.interface';
import { QuestionService } from '../../../../core/services/api/question.service';
import { SnackBarService } from '../../../../core/services/snack-bar.service';
import { ConfirmComponent } from '../../../popups/confirm/confirm.component';
import { QuestionFormComponent } from '../question-form/question-form.component';

@Component({
  selector: 'app-question-add',
  templateUrl: './question-add.component.html',
  styleUrls: ['./question-add.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [QuestionFormComponent],
})
export class QuestionAddComponent implements CanComponentDeactivate {
  private formEdited: boolean;

  constructor(
    private destroyRef: DestroyRef,
    private matDialog: MatDialog,
    private questionService: QuestionService,
    private route: ActivatedRoute,
    private router: Router,
    private snackBarService: SnackBarService,
  ) {}

  public canDeactivate(): boolean | Observable<boolean> {
    if (this.formEdited) {
      const dialogRef = this.matDialog.open(ConfirmComponent, {
        data: {
          title: 'Are you sure?',
          description: 'Changes you made will not be saved',
        },
      });

      return dialogRef.afterClosed().pipe(map(Boolean));
    }

    return true;
  }

  public setFormEdited(formEdited: boolean): void {
    this.formEdited = formEdited;
  }

  public addQuestion(question: any): void {
    this.questionService.setSaveButtonState(true);
    this.questionService
      .postQuestion$(question)
      .pipe(
        catchError((err) => {
          this.questionService.setSaveButtonState(false);
          return throwError(() => err);
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((newQuestion) => {
        this.setFormEdited(false);
        this.router.navigate([newQuestion.uuid], { relativeTo: this.route });
        this.snackBarService.openSnackBar({
          content: {
            message: 'Question created successfully',
          },
          status: 'success',
        });
      });
  }
}
