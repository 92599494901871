import { ActivatedRouteSnapshot, CanDeactivateFn, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { CanComponentDeactivate } from '../models/interfaces/can-deactivate.interface';

export const questionLeaveGuard: CanDeactivateFn<CanComponentDeactivate> = (
  component: CanComponentDeactivate,
  _currentRoute: ActivatedRouteSnapshot,
  _currentState: RouterStateSnapshot,
  _nextState: RouterStateSnapshot,
): Observable<boolean> | Promise<boolean> | boolean => {
  return component.canDeactivate ? component.canDeactivate() : true;
};
