<input #fileInput class="file-input" type="file" [accept]="accept" (change)="fileChanged($event)" />

<div class="container" [class.disabled]="control.disabled">
  <div>
    <label class="label"
      >{{ label }}
      @if (required) {
        <span>*</span>
      }
    </label>

    <div class="placeholder">
      {{ control.value?.name || control.value || 'No file uploaded yet.' }}
    </div>
  </div>

  <button
    type="button"
    class="g-unstyled-button file-button"
    (click)="control.value ? deleteFile() : fileInput.click()"
  >
    <mat-icon [ngStyle]="{ color: control.value ? 'red' : 'gray' }">{{ control.value ? 'delete' : 'upload' }}</mat-icon>
  </button>
</div>

<div class="error">
  @if (control.touched && !correctFileSize) {
    <span class="error__text">
      {{ ERROR_500MB }}
    </span>
  }
  @if (control.touched && control.errors?.required) {
    <span class="error__text">Please choose a file</span>
  }
</div>
