import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { LocalStorageKeys } from '../models/enums/local-storage-keys';
import { AuthService } from '../services/auth.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private accessTokenRefreshing = false;
  private accessTokenSubject = new BehaviorSubject<string>(null);

  constructor(private authService: AuthService) {}

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!request.url.includes(environment.apiBase) && !request.url.includes(environment.apiUrl)) {
      return next.handle(request);
    }

    let authorizedReq = request;

    const token = JSON.parse(localStorage.getItem(LocalStorageKeys.AccessToken));

    if (token !== null) {
      authorizedReq = this.addAuthorizationHeader(request, token);
    }

    return next.handle(authorizedReq).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse && (error.status === 401 || error.status === 403)) {
          localStorage.setItem(LocalStorageKeys.AccessToken, null);
          return this.handleUnauthorizedError(authorizedReq, next);
        }

        return throwError(error);
      }),
    );
  }

  private handleUnauthorizedError(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.accessTokenRefreshing) {
      this.accessTokenRefreshing = true;
      this.accessTokenSubject.next(null);

      const refreshToken = localStorage.getItem(LocalStorageKeys.RefreshToken);

      if (refreshToken !== null) {
        return this.authService.refreshToken$().pipe(
          switchMap(({ accessToken }) => {
            this.accessTokenRefreshing = false;
            this.accessTokenSubject.next(accessToken);

            return next.handle(this.addAuthorizationHeader(request, accessToken)).pipe(
              catchError((err) => {
                this.accessTokenRefreshing = false;
                return throwError(() => err);
              }),
            );
          }),
        );
      }
    }

    return this.accessTokenSubject.pipe(
      filter((token) => token !== null),
      take(1),
      switchMap((token) => next.handle(this.addAuthorizationHeader(request, token))),
    );
  }

  private addAuthorizationHeader(request: HttpRequest<any>, accessToken: string) {
    return request.clone({ headers: request.headers.set('Authorization', accessToken) });
  }
}
