import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormArray, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatMiniFabButton } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatFormField } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatPaginator } from '@angular/material/paginator';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import {
  MatCell,
  MatCellDef,
  MatColumnDef,
  MatHeaderCell,
  MatHeaderCellDef,
  MatHeaderRow,
  MatHeaderRowDef,
  MatRow,
  MatRowDef,
  MatTable,
  MatTableDataSource,
} from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { Subject, debounceTime, filter, finalize, switchMap, takeUntil } from 'rxjs';
import { CheckPermissionDirective } from '../../../core/directives/check-permission/check-permission.directive';
import { Permission } from '../../../core/models/enums/permissions.enum';
import { ArticleWrongRecord } from '../../../core/models/interfaces/part.interface';
import { PartsService } from '../../../core/services/parts.service';
import { SnackBarService } from '../../../core/services/snack-bar.service';

@Component({
  selector: 'app-parts',
  templateUrl: './parts.component.html',
  styleUrls: ['../../styles/table.scss', './parts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CheckPermissionDirective,
    MatProgressSpinner,
    MatTable,
    ReactiveFormsModule,
    MatColumnDef,
    MatHeaderCellDef,
    MatHeaderCell,
    MatMiniFabButton,
    MatIcon,
    MatCellDef,
    MatCell,
    MatFormField,
    MatInput,
    MatCheckbox,
    MatHeaderRowDef,
    MatHeaderRow,
    MatRowDef,
    MatRow,
    MatPaginator,
  ],
})
export class PartsComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator) public paginator: MatPaginator;

  public readonly defaultPageSize = 10;
  public dataSource = new MatTableDataSource<any>([]);

  public dataLoading = false;
  public partsTotalCount = 0;
  public partsCurrentCount = 0;
  public displayedColumns = [
    'actions',
    'name',
    'labelEn',
    'labelRu',
    'labelDe',
    'labelLa',
    'articleEn',
    'articleRu',
    'articleDe',
  ];

  public partsFilterForm = new FormGroup<any>({
    name: new FormControl<string>(''),
    labelEn: new FormControl<string>(''),
    labelDe: new FormControl<string>(''),
    labelRu: new FormControl<string>(''),
    labelLa: new FormControl<string>(''),
    emptylabelEn: new FormControl<boolean>(undefined),
    emptylabelDe: new FormControl<boolean>(undefined),
    emptylabelRu: new FormControl<boolean>(undefined),
    emptylabelLa: new FormControl<boolean>(undefined),
    articleEn: new FormControl<boolean>(undefined),
    articleRu: new FormControl<boolean>(undefined),
    articleDe: new FormControl<boolean>(undefined),
  });

  public dataFormArray: FormArray;

  public articlesErrors: ArticleWrongRecord[] = [];

  public permission = Permission;

  private partsToUpdate = new Map();

  private newPageSubject = new Subject<void>();

  private queryObj = {};

  constructor(
    private cdRef: ChangeDetectorRef,
    private destroyRef: DestroyRef,
    private partsService: PartsService,
    private route: ActivatedRoute,
    private snackBarService: SnackBarService,
  ) {}

  public ngOnInit(): void {
    this.initTableData();
  }

  public ngAfterViewInit(): void {
    this.initTableFilterAndPagination();

    this.paginator.page.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((pageEvent) => {
      this.setPartsToUpdate();
      this.newPageSubject.next();
      this.initTableData(this.queryObj, {
        pageNo: pageEvent.pageIndex,
        pageSize: pageEvent.pageSize,
      });
    });
  }

  public saveParts(): void {
    this.setPartsToUpdate();
    this.dataLoading = true;
    this.partsService
      .updatePart$(Array.from(this.partsToUpdate.values()))
      .pipe(
        finalize(() => {
          this.dataLoading = false;
          this.cdRef.markForCheck();
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe({
        next: (data) => {
          this.articlesErrors = data.wrongArticles;

          this.dataFormArray.controls.forEach((group, index) => {
            const row = data.updatedParts.find((part) => part.name === group.get('name').value);

            if (!row) return;

            group.patchValue(data.updatedParts[index]);
            group.get('reviewArticleDe').setValue(false);
            group.get('reviewArticleEn').setValue(false);
            group.get('reviewArticleRu').setValue(false);
          });

          this.snackBarService.openSuccessSnackBar({
            message: 'Parts were successfully updated',
          });

          this.partsToUpdate = new Map();
          this.dataFormArray.controls.forEach((control) => {
            control.markAsPristine();
            control.markAsUntouched();
          });
        },
        error: (err) => {
          this.snackBarService.openErrorSnackBar(err.error);
        },
      });
  }

  private initTableData(
    queryObj = {},
    pagination: { pageNo: number; pageSize: number; sortBy?: string } = {
      pageNo: 0,
      pageSize: this.defaultPageSize,
      sortBy: 'name',
    },
  ): void {
    this.dataLoading = true;
    this.cdRef.markForCheck();

    this.route.params
      .pipe(
        filter(Boolean),
        switchMap((params) =>
          this.partsService.getPart$(params['folder'], queryObj, pagination).pipe(
            finalize(() => {
              this.dataLoading = false;
            }),
          ),
        ),
        takeUntil(this.newPageSubject),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe({
        next: (res) => {
          const groups = res.parts.map((item) => {
            const group = {};
            Object.entries(item).forEach(([key, value]) => {
              group[key] = new FormControl(value);
            });
            group['reviewArticleDe'] = new FormControl(false);
            group['reviewArticleEn'] = new FormControl(false);
            group['reviewArticleRu'] = new FormControl(false);
            return new FormGroup(group);
          });
          this.dataFormArray = new FormArray(groups);
          this.dataSource = new MatTableDataSource(this.dataFormArray.controls);
          this.partsTotalCount = res.total;
          this.partsCurrentCount = res.count;
          this.watchArticlesUpdate();
          this.cdRef.markForCheck();
        },
        error: (err) => {
          this.snackBarService.openErrorSnackBar(err.error);
          this.dataLoading = false;
          this.cdRef.markForCheck();
        },
      });
  }

  private initTableFilterAndPagination(): void {
    this.dataSource.paginator = this.paginator;

    const controls = Object.entries(this.partsFilterForm.controls);

    controls.forEach((control) => {
      control[1].valueChanges.pipe(debounceTime(500), takeUntilDestroyed(this.destroyRef)).subscribe((val) => {
        this.queryObj['fieldForFilter'] = control[0].replace('empty', '');

        if (!val) {
          this.queryObj = {};
        } else if (typeof val === 'string') {
          this.queryObj['searchText'] = val;
          this.queryObj['filter'] = 'exists';
        } else if (typeof val === 'boolean') {
          this.queryObj['filter'] = 'empty';
        }
        this.newPageSubject.next();

        this.initTableData(this.queryObj, {
          pageNo: 0,
          pageSize: this.paginator.pageSize,
        });

        controls.forEach((c) => {
          if (c[0] !== control[0]) {
            c[1].setValue(null, { emitEvent: false });
          }
        });
      });
    });
  }

  private watchArticlesUpdate(): void {
    const langs = ['De', 'En', 'Ru'];

    this.dataFormArray.controls.forEach((group) => {
      const initialValue = langs.map((lang) => group.get(`article${lang}`).value);

      langs.forEach((lang) => {
        group
          .get(`article${lang}`)
          .valueChanges.pipe(takeUntil(this.newPageSubject), takeUntilDestroyed(this.destroyRef))
          .subscribe((articleLink) => {
            group.get(`reviewArticle${lang}`).setValue((articleLink || null) !== initialValue[0]);
          });
      });
    });
  }

  private setPartsToUpdate(): void {
    this.dataFormArray.controls.forEach((control) => {
      if (control.dirty) {
        this.partsToUpdate.set(control.value.uuid, control.value);
      }
    });
  }
}
